<template>
	<div class="form-content transformer">
		<div>
			<el-form ref="ruleForm" :model="chaxun" :inline="true">
				<el-form-item label="活动名称">
					<el-input v-model="chaxun.bannertitle"></el-input>
				</el-form-item>
				<el-form-item label="活动时间">
					<el-date-picker v-model="chaxun.time" type="daterange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期">
					</el-date-picker>
				</el-form-item>

				<el-form-item label="所属社区">
					<el-select v-model="chaxun.community" clearable collapse-tags multiple placeholder="请选择">
						<el-option v-for="item in communitys" :key="item.levelcode" :label="item.name"
							:value="item.levelcode">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="banner位置">
					<el-select clearable v-model="chaxun.bannertype">
					
						<el-option label="首页" value="0"></el-option>
						<el-option label="活动首页" value="1"></el-option>
						<el-option label="预约首页" value="2"></el-option>
						<el-option label="小程序静态图" value="99"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="发布状态">
					<el-select clearable v-model="chaxun.delflag">
						<el-option label="上架" value="0"></el-option>
						<el-option label="下架" value="1"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="search()" style="float: left;">
						<i class="el-icon-search"></i>
						查询
					</el-button>
					<el-button type="primary" @click="add()" style="float: left;"><i class="el-icon-plus"></i> 新增
					</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div>
			<div v-loading="loading">
				<p style="font-size: 16px;color: #409EFF;margin-top: 10px;">共找到 <span
						style="font-weight: bold;">{{tableData.totalRow?tableData.totalRow:0}}</span>
					条数据</p>
				<el-table :data="tableData.list" border style="width: 100%;margin-top: 20px;">
					<el-table-column type="index" width="50" label="序号" show-overflow-tooltip align="center">
						<template scope="scope"><span>{{scope.$index+(currentPage - 1) * fenye.pagesize + 1}}
							</span></template>
					</el-table-column>
					<el-table-column prop="bannername" label="banner名称" show-overflow-tooltip align="center">						
					</el-table-column>
					<el-table-column prop="typename" label="banner位置" show-overflow-tooltip align="center">
					</el-table-column>
					<!-- <el-table-column label="banner位置" show-overflow-tooltip align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.bannertype == '0'">公告</span>
							<span v-if="scope.row.bannertype == '1'">预约</span>
							<span v-if="scope.row.bannertype == '2'">活动</span>
							<span v-if="scope.row.bannertype == '99'">小程序静态图</span>
						</template>
					</el-table-column> -->
					<!-- <el-table-column label="类型"  width="100">
						<template slot-scope="scope">
							<span v-if="scope.row.fileflag == '0'">图片</span>
							<span v-if="scope.row.fileflag == '1'">链接地址</span>
						</template>
					</el-table-column> -->
					<!-- <el-table-column prop="activitytitle" label="活动名称">
					</el-table-column> -->
					<el-table-column prop="begintime" label="开始时间" show-overflow-tooltip align="center">
					</el-table-column>
					<el-table-column prop="endtime" label="结束时间" show-overflow-tooltip align="center">
					</el-table-column>
					<el-table-column label="发布状态" show-overflow-tooltip align="center">
						<template slot-scope="scope">
							<span style="color: #409EFF;" v-if="scope.row.delflag == '0'">上架状态</span>
							<span style="color: red;" v-if="scope.row.delflag == '1'">下架状态</span>
						</template>
					</el-table-column>
					<el-table-column prop="createtime" label="创建时间" show-overflow-tooltip align="center">
					</el-table-column>
					<el-table-column label="操作" width="200" show-overflow-tooltip align="center">
						<template slot-scope="scope">
							<div class="step">
								<el-button type="primary" @click="edit(scope.row)">修改</el-button>
								<el-button type="danger" v-if="scope.row.delflag == '0'" @click="shanchu(scope.row.id)">
									下架</el-button>
								<el-button type="success" v-if="scope.row.delflag == '1'"
									@click="putawayFn(scope.row.id)">上架</el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="block">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="tableData.totalRow">
					</el-pagination>
				</div>
			</div>
		</div>

		<el-dialog title="创建" width='800px' :visible.sync="xiangqing" :close-on-click-modal="false"
			:before-close="handleClose">
			<div class="content" v-loading="loading2" element-loading-text="创建中"
				element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
				<el-form ref="form" :model="form1" label-width="110px">
					<el-form-item label="图片上传">
						<el-upload ref='upload' :limit="1" accept=".jpg,.jpeg,.png,.JPG,.JPEG" :on-change="handleChange"
							action="#" list-type="picture-card" :on-preview="handlePictureCardPreview"
							:on-remove="handleRemove" :auto-upload="false" :on-exceed="handleExceed">
							<i class="el-icon-plus"></i>
						</el-upload>
					</el-form-item>

					<el-form-item label="banner名称">
						<el-input v-model="form1.bannername"></el-input>
					</el-form-item>

					<el-form-item label="所属社区">
						<el-select v-model="form1.arealevel" clearable collapse-tags multiple placeholder="请选择">
							<el-option v-for="item in communitys" :key="item.levelcode" :label="item.name"
								:value="item.levelcode">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="位置">
						<el-radio-group v-model="form1.bannertype">
							<el-radio v-for="bannertype in bannerTypes" :key="bannertype.id" :label="bannertype.bannertype">{{bannertype.bannername}}</el-radio>
						</el-radio-group>
					</el-form-item>

					<!-- <el-form-item label="类型">
						<el-radio-group v-model="form1.status">
							<el-radio label="0">图片</el-radio>
							<el-radio label="1">关联活动</el-radio>
						</el-radio-group>
					</el-form-item> -->
					<el-form-item label="活动列表" v-if="form1.status == 1">
						<el-select v-model="form1.actid" placeholder="活动列表">
							<el-option v-for="item in actlist" :key="item.activityuuid" :label="item.activitytitle"
								:value="item.activityuuid">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="时间">
						<el-date-picker v-model="form1.time" type="datetimerange" range-separator="至"
							start-placeholder="开始时间" end-placeholder="结束时间">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="排序字段">
						<el-input v-model="form1.num" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
					</el-form-item>
					<div style="text-align: center;">
						<el-button type="primary" style="display: inline-block;" @click="submit1()">提交</el-button>
					</div>
				</el-form>
			</div>
		</el-dialog>

		<el-dialog title="修改" width='800px' :visible.sync="xiangqing3" :close-on-click-modal="false"
			:before-close="handleClose3">
			<div class="content" v-loading="loading3" element-loading-text="修改"
				element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
				<el-form ref="form" :model="form3" label-width="110px">
					<el-form-item label="图片上传">
						<el-upload multiple :limit="1" accept=".jpg,.jpeg,.png,.JPG,.JPEG" :on-change="handleChange3"
							action="#" list-type="picture-card" :on-preview="handlePictureCardPreview3"
							:on-remove="handleRemove3" :auto-upload="false" :on-exceed="handleExceed3"
							:file-list="list3">
							<i class="el-icon-plus"></i>
						</el-upload>
					</el-form-item>


					<el-form-item label="banner名称">
						<el-input v-model="form3.bannername"></el-input>
					</el-form-item>

					<el-form-item label="所属社区">
						<el-select v-model="form3.arealevel" clearable collapse-tags multiple placeholder="请选择">
							<el-option v-for="item in communitys" :key="item.levelcode" :label="item.name"
								:value="item.levelcode">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="位置">
						<el-radio-group v-model="form3.bannertype">
							<el-radio v-for="bannertype in bannerTypes" :key="bannertype.id" :label="bannertype.bannertype">{{bannertype.bannername}}</el-radio>
						</el-radio-group>
					</el-form-item>
					<!-- <el-form-item label="类型">
						<el-radio-group v-model="form3.status">
							<el-radio label="0">图片</el-radio>
							<el-radio label="1">关联活动</el-radio>
						</el-radio-group>
					</el-form-item> -->

					<el-form-item label="活动列表" v-if="form3.status == 1">
						<el-select v-model="form3.actid" placeholder="活动列表">
							<el-option v-for="item in actlist3" :key="item.activityuuid" :label="item.activitytitle"
								:value="item.activityuuid">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="时间">
						<el-date-picker v-model="form3.time" type="datetimerange" range-separator="至"
							start-placeholder="开始时间" end-placeholder="结束时间">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="排序字段">
						<el-input v-model="form3.num" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
					</el-form-item>
					<div style="text-align: center;">
						<el-button type="primary" style="display: inline-block;" @click="submit3()">提交</el-button>
					</div>
				</el-form>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>
		<el-dialog :visible.sync="dialogVisible3">
			<img width="100%" :src="dialogImageUrl3" alt="">
		</el-dialog>
	</div>

</template>

<script>
	import {
		FindBannnerList,
		FindActivityList,
		DelBanner,
		UpDateBanner,
		AddBannner,
		putawayBanner,
		bannerConfigType
	} from '@/api/banner'
	import {
		communityOfUser
	} from '@/api/system';
	export default {
		components: {},
		data() {
			return {
				chaxun: {
					bannertitle: '',
					time: '',
					bannertype: '',
					delflag: '',
					community: []
				},
				tableData: [],
				currentPage: 1,
				fenye: {
					pagesizes: [10, 50, 100],
					pagesize: 10,
					totalnum: 9
				},
				show: false,
				text: '展开',
				isIndeterminate: true,
				duoxuanList: [],
				checkList: [],
				xiangqing: false,
				loading: false,
				loading2: false,
				form1: {
					photoid: '',
					time: '',
					status: '0',
					bannertype: '',
					num: '',
					actid: '',
					bannername: '',
					arealevel: []
				},
				actlist: [],
				dialogImageUrl: '',
				dialogVisible: false,
				file1: '',
				xiangqing3: false,
				loading3: false,
				form3: {
					photoid: '',
					time: '',
					status: '0',
					bannertype: '',
					num: '',
					actid: '',
					bannername: '',
					arealevel: []
				},
				actlist3: [],
				dialogImageUrl3: '',
				dialogVisible3: false,
				file3: '',
				list3: [],
				id: '',
				communitys: [],
				bannerTypes: []

			}
		},
		methods: {
			//获取banner配置类型
			bannerConfigTypeFn(){
				bannerConfigType({
					userid: this.GLOBAL.adminId()
				}).then(res => {
					if (res.result == 200) {
						this.bannerTypes = res.detail;
						console.log(this.bannerTypes)
					} else {
						this.$message.error(res.description);
					}
				})
			},
			//获取当前登录用户的管辖社区
			getCommunityOfUser() {
				communityOfUser({
					userid: this.GLOBAL.adminId()
				}).then(res => {
					if (res.result == 200) {
						this.communitys = res.detail;
						this.getData();
					} else {
						this.$message.error(res.description);
					}
				})
			},
			getTime(a) {
				if (a == '' || a == null) {
					return '';
				} else {
					a = new Date(a);
					var year = a.getFullYear();
					var mon = a.getMonth() + 1;
					var month = mon > 9 ? mon : '0' + mon;
					var day = a.getDate() > 9 ? a.getDate() : '0' + a.getDate();
					var hour = a.getHours() > 9 ? a.getHours() : '0' + a.getHours();
					var min = a.getMinutes() > 9 ? a.getMinutes() : '0' + a.getMinutes();
					var s = a.getSeconds() > 9 ? a.getSeconds() : '0' + a.getSeconds();
					return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s;

				}
			},
			getstartTime(a) {
				if (a == '' || a == null) {
					return '';
				} else {
					var year = a.getFullYear();
					var mon = a.getMonth() + 1;
					var month = mon > 9 ? mon : '0' + mon;
					var day = a.getDate() > 9 ? a.getDate() : '0' + a.getDate();
					return year + '-' + month + '-' + day + ' 00:00:00';
				}
			},
			getendTime(a) {
				if (a == '' || a == null) {
					return '';
				} else {
					var year = a.getFullYear();
					var mon = a.getMonth() + 1;
					var month = mon > 9 ? mon : '0' + mon;
					var day = a.getDate() > 9 ? a.getDate() : '0' + a.getDate();
					return year + '-' + month + '-' + day + ' 23:59:59';
				}
			},
			//查询数据
			search() {
				this.currentPage = 1;
				this.getData()
			},
			handleCommand(command) {
				this.chaxun.command = command
				this.chaxun.date2 = [];
				this.chaxun.date1 = ""
			},
			handleCommandDz(command) {
				this.chaxun.commandDz = command
			},
			handleSizeChange(val) {
				this.fenye.pagesize = val;
				this.getData()
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getData()
			},
			// handleCheckAllChange(val) {
			// 	this.checkedCities = val ? cityOptions : [];
			// 	this.isIndeterminate = false;
			// },
			handleCheckedCitiesChange(value) {
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.cities.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
			},

			//获取banner列表数据
			getData() {
				let arealevelArr = []
				if (this.chaxun.community.length == 0) {
					this.communitys.map(item => {
						arealevelArr.push(item.levelcode)
					})
				} else {
					arealevelArr = [...this.chaxun.community];
				}
				this.loading = true;
				var startstart;
				var startend;
				if (this.chaxun.time == '' || this.chaxun.time == null) {
					startstart = '';
					startend = '';
				} else {
					startstart = this.getstartTime(this.chaxun.time[0]);
					startend = this.getendTime(this.chaxun.time[1]);
				}
				let data = {
					"userid": this.GLOBAL.adminId(),
					'pageNum': this.currentPage,
					'pageSize': this.fenye.pagesize,
					'bannertitle': this.chaxun.bannertitle,
					// code:this.$route.meta.labelcode?this.$route.meta.labelcode:'',
					begintime: startstart,
					endtime: startend,
					bannertype: this.chaxun.bannertype,
					delflag: this.chaxun.delflag,
					arealevel: arealevelArr.join(',')
				}
				FindBannnerList(data).then(res => {
					this.loading = false;
					if (res.result == 200) {
						this.tableData = res.detail.BannerPage;
					} else {
						this.$message.error(res.description);
					}
				}).catch(err => {
					this.loading = false;
				})
			},
			handleClose(done) {
				this.close();
				done();
			},
			//重置新增banner表单
			close() {
				this.form1 = {
					photoid: '',
					time: '',
					status: '',
					bannertype: '',
					num: '',
					actid: '',
					bannername: '',
					arealevel: []
				};
				this.file1 = '';
				this.$refs.upload.clearFiles();
			},
			handleChange(file, fileList) {
				//console.log(file)
				var that = this;
				that.file1 = file.raw;
			},
			handleExceed(files, fileList) {
				this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
			},
			handleRemove(file, fileList) {
				var that = this;
				that.file1 = '';
				that.form1.photoid = '';
			},


			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			//新增banner
			add() {
				this.bannerConfigTypeFn();
				this.xiangqing = true;
				FindActivityList({
					userid: this.GLOBAL.adminId()
				}).then(res => {
					if (res.result == 200) {
						this.actlist = res.detail.activitylist;
					} else {
						this.$message.error(res.description);
					}
				})

			},
			//提交——新增banner
			submit1() {
				var that = this;
				console.log(that.form1)
				if (that.file1 == '') {
					that.$message.warning('请选择图片');
					return;
				}
				if (that.form1.bannername == '') {
					that.$message.warning('请输入banner名称');
					return;
				}
				if (that.form1.arealevel.length == 0) {
					that.$message.warning('请选择所属社区');
					return;
				}

				if (that.form1.bannertype === '') {
					that.$message.warning('请选择位置');
					return;
				}
				if (that.form1.status == '') {
					that.$message.warning('请选择类型');
					return;
				}
				if (that.form1.status == '1' && that.form1.actid == '') {
					that.$message.warning('请选择活动');
					return;
				}
				if (that.form1.time == '' || that.form1.time == null) {
					that.$message.warning('请选择时间');
					return;
				}
				if (that.form1.num == '') {
					that.$message.warning('请填写排序字段');
					return;
				}
				that.loading2 = true;
				var start = that.getTime(that.form1.time[0]);
				var end = that.getTime(that.form1.time[1]);
				var formData = new FormData();
				formData.append('userid', that.GLOBAL.adminId());
				formData.append('upfilelist', that.file1);

				that.$axios.post('/fileutil/Miniupload', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(function(res) {
					if (res.data.result == 200) {
						that.form1.photoid = res.data.detail;
						AddBannner({
							"userid": that.GLOBAL.adminId(),
							'begintime': start,
							'endtime': end,
							'fileflag': that.form1.status,
							'bannername': that.form1.bannername,
							'bannertype': that.form1.bannertype,
							'activitypic': that.form1.photoid,
							'activityid': that.form1.actid,
							'ordernum': that.form1.num,
							'arealevel': that.form1.arealevel.join(',')
						}).then(res => {
							that.loading2 = false;
							if (res.result == 200) {
								that.$message.success('创建成功');
								that.xiangqing = false;
								that.close();
								that.getData();
							} else {
								this.$message.error(res.description);
							}
						}).catch(err => {
							that.loading2 = false;
						})


					} else {
						that.loading2 = false;
						that.$message.error(res.data.description);
					}
				}, function(res) {

				});
			},



			handleClose3(done) {
				this.close3();
				done();
			},
			//重置修改banner表单字段
			close3() {
				this.form3 = {
					photoid: '',
					time: '',
					status: '',
					bannertype: '',
					num: '',
					actid: '',
					bannername: '',
					arealevel: []
				};
				this.file3 = '';
				this.list3 = [];
				this.id = '';
			},
			handleChange3(file, fileList) {
				var that = this;
				that.file3 = file.raw;
			},
			handleExceed3(files, fileList) {
				this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
			},
			handleRemove3(file, fileList) {
				var that = this;
				that.file3 = '';
				that.form3.photoid = '';
			},
			handlePictureCardPreview3(file) {
				this.dialogImageUrl3 = file.url;
				this.dialogVisible3 = true;
			},

			//修改banner
			edit(a) {
				console.log(a)
				this.xiangqing3 = true;
				/* FindActivityList({
					userid: this.GLOBAL.adminId()
				}).then(res => {
					if (res.result == 200) {
						this.actlist3 = res.detail.activitylist;
					} else {
						this.$message.error(res.description);
					}
				}) */
				this.form3.photoid = a.picid;
				this.form3.status = a.fileflag;
				this.form3.bannertype = a.bannertype;
				this.form3.bannername = a.bannername;
				this.list3.push({
					name: a.filename,
					url: a.path
				});
				if (a.activityuuid == null) {
					this.form3.actid = '';
				} else {
					this.form3.actid = a.activityuuid;
				}
				this.form3.time = [];
				this.form3.time.push(a.begintime);
				this.form3.time.push(a.endtime);
				this.form3.num = a.ordernum;
				this.id = a.id
				if (a.areacode) {
					this.form3.arealevel = a.areacode.split(',')
				}
			},
			//提交——修改banner
			submit3() {
				var that = this;
				if (that.file3 == '' && that.form3.photoid == '') {
					that.$message.warning('请选择图片');
					return;
				}
				if (that.form3.arealevel.length == 0) {
					that.$message.warning('请选择所属社区');
					return;
				}
				if (that.form3.bannertype === '') {
					that.$message.warning('请选择位置');
					return;
				}
				if (that.form3.status == '') {
					that.$message.warning('请选择类型');
					return;
				}
				if (that.form3.status == '1' && that.form3.actid == '') {
					that.$message.warning('请选择活动');
					return;
				}
				if (that.form3.time == '' || that.form3.time == null) {
					that.$message.warning('请选择时间');
					return;
				}
				if (that.form3.num == '') {
					that.$message.warning('请填写排序字段');
					return;
				}
				that.loading3 = true;
				var start = that.getTime(that.form3.time[0]);
				var end = that.getTime(that.form3.time[1]);
				if (that.form3.photoid == '') {
					var formData = new FormData();
					formData.append('userid', that.GLOBAL.adminId());
					formData.append('upfilelist', that.file3);
					that.$axios.post('/fileutil/Miniupload', formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}).then(function(res) {
						if (res.data.result == 200) {
							that.form3.photoid = res.data.detail;
							UpDateBanner({
								"userid": that.GLOBAL.adminId(),
								'begintime': start,
								'endtime': end,
								'fileflag': that.form3.status,
								'bannername': that.form3.bannername,
								'bannertype': that.form3.bannertype,
								'activitypic': that.form3.photoid,
								'activityid': that.form3.actid,
								'ordernum': that.form3.num,
								'bannerid': that.id,
								'arealevel': that.form3.arealevel.join(',')
							}).then(res => {
								that.loading3 = false;
								if (res.result == 200) {
									that.$message.success('修改成功');
									that.xiangqing3 = false;
									that.close3();
									that.getData();
								} else {
									this.$message.error(res.description);
								}
							}).catch(err => {
								that.loading3 = false;
							})


						} else {
							that.loading3 = false;
							that.$message.error(res.data.description);
						}
					}, function(res) {

					});
				} else {
					UpDateBanner({
						"userid": that.GLOBAL.adminId(),
						'begintime': start,
						'endtime': end,
						'fileflag': that.form3.status,
						'bannername': that.form3.bannername,
						'bannertype': that.form3.bannertype,
						'activitypic': that.form3.photoid,
						'activityid': that.form3.actid,
						'ordernum': that.form3.num,
						'bannerid': that.id,
						'arealevel': that.form3.arealevel.join(',')
					}).then(res => {
						that.loading3 = false;
						if (res.result == 200) {
							that.$message.success('修改成功');
							that.xiangqing3 = false;
							that.close3();
							that.getData();
						} else {
							this.$message.error(res.description);
						}
					})

				}

			},
			//上架
			putawayFn(id) {
				this.$confirm('是否确认上架？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					putawayBanner({
						"userid": this.GLOBAL.adminId(),
						'bannerid': id
					}).then(res => {
						if (res.result == 200) {
							this.$message({
								type: 'success',
								message: '上架成功!'
							});
							this.getData();
						} else {
							this.$message.error(res.description);
						}
					})

				}).catch(() => {

				});
			},
			//下架
			shanchu(a) {
				this.$confirm('是否下架该banner？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					DelBanner({
						"userid": this.GLOBAL.adminId(),
						'bannerid': a
					}).then(res => {
						if (res.result == 200) {
							this.$message({
								type: 'success',
								message: '下架成功!'
							});
							this.getData();
						} else {
							this.$message.error(res.description);
						}
					})

				}).catch(() => {

				});
			}
		},
		mounted() {
			this.bannerConfigTypeFn();
			this.getCommunityOfUser();
		},
		watch: {

		}
	}
</script>

<style scoped="scoped">
	.form-content {
		padding: 18px;
	}

	.block {
		margin-top: 20px;
		text-align: right;
	}

	.content {
		min-height: calc(100% - 39px);
		padding: 18px;
	}
</style>
